import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import TabsPage from '../views/TabsPage.vue';
import {FirebaseApp} from "firebase/app";
import {getAuth, Auth} from 'firebase/auth/cordova';

let _firebaseApp: FirebaseApp | null = null;
let _auth: Auth | null = null;

export function setFirebaseApp(firebaseApp: FirebaseApp) {
  _firebaseApp = firebaseApp;
  _auth = getAuth(_firebaseApp);
}

function isAuthenticated(to: any, from: any, next: any) {
  if (!_firebaseApp){
    console.log('FirebaseApp not initialised in router!');
    return next('/login');
  }
  if (getAuth(_firebaseApp).currentUser?.uid) {
    console.log('Router: User is logged in.')
    next();
  } else {
    next('/login');
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/:eventId/live'
  },
  {
    path: '/manageEvents',
    component: () => import('@/views/MoPage.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/events',
    component: () => import('@/views/EventSelectionPage.vue'),
    beforeEnter: isAuthenticated
  },
  {
    path: '/tabs/:eventId/',
    beforeEnter: isAuthenticated,
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/:eventId/live'
      },
      {
        path: 'live',
        component: () => import('@/views/MediaPlayerPage.vue')
      },
      {
        path: 'match',
        component: () => import('@/views/MatchOfficialsPage.vue')
      },
      {
        path: 'tournament',
        component: () => import('@/views/FixturesPage.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
