import { Firestore, getDocs, collection, doc, getDoc, addDoc, updateDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { IRepository } from './IRepository';
import { Entity } from '../entities/Entity';

export class FirestoreRepository<T extends Entity> implements IRepository<T> {
    constructor(private firestore: Firestore, private collectionName: string) { }

    async get(id: string): Promise<T | null> {
        const docRef = doc(this.firestore, this.collectionName, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const entity = docSnap.data() as T;
            entity.id = docSnap.id;
            return entity;
        } else {
            return null;
        }
    }

    async list(): Promise<T[]> {
        const querySnapshot = await getDocs(collection(this.firestore, this.collectionName));
        return querySnapshot.docs.map(doc => {
            const entity = doc.data() as T;
            entity.id = doc.id;
            return entity;
        });
    }

    async add(item: T): Promise<string> {
        const docRef = await addDoc(collection(this.firestore, this.collectionName), item as any);
        return docRef.id;
    }

    async update(item: T): Promise<void> {
        const docRef = doc(this.firestore, this.collectionName, item.id);
        await updateDoc(docRef, item as any);
    }


    async delete(id: string): Promise<void> {
        const docRef = doc(this.firestore, this.collectionName, id);
        await deleteDoc(docRef);
    }

    onEntityUpdated(id: string, callback: (item: T) => void): void {
        const docRef = doc(this.firestore, this.collectionName, id);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                const entity = doc.data() as T;
                entity.id = doc.id;
                callback(entity);
            }
        });
    }

    onListUpdated(callback: (items: T[]) => void): void {
        const querySnapshot = collection(this.firestore, this.collectionName);
        const unsubscribe = onSnapshot(querySnapshot, (querySnapshot) => {
            const items: T[] = [];
            querySnapshot.forEach((doc) => {
                const entity = doc.data() as T;
                entity.id = doc.id;
                items.push(entity);
            });
            callback(items);
        });
    }
}