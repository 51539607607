import { Firestore } from "firebase/firestore";
import { FirestoreRepository } from "./FirestoreRepository";
import { Event } from "../entities/Event";
import { MatchOfficials } from "../entities/MatchOfficials";

export class RepositoryService{

    private readonly _firestore: Firestore;
    public EventsRepository: FirestoreRepository<Event>;
    public MatchOfficialsRepository: FirestoreRepository<MatchOfficials>;

    constructor(firestore: Firestore) {
        this._firestore = firestore;
        this.EventsRepository = new FirestoreRepository(this._firestore, "events");
        this.MatchOfficialsRepository = new FirestoreRepository(this._firestore, "match-officials");
    }
}