import { createApp } from 'vue'
import App from './App.vue'
import {setFirebaseApp} from './router';
import router from './router';

import { IonicVue } from '@ionic/vue';

import './registerServiceWorker';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { RepositoryService } from './domain/repository/RepositoryService';

const app = createApp(App)
  .use(IonicVue)
  .use(router);

const firebaseConfig = {
  apiKey: "AIzaSyBKdUf_MqSxIpE0_xtJ-CQ6eRXYspon2GE",
  authDomain: "ref-mic-c3c0c.firebaseapp.com",
  projectId: "ref-mic-c3c0c",
  storageBucket: "ref-mic-c3c0c.appspot.com",
  messagingSenderId: "140091551316",
  appId: "1:140091551316:web:58e538b056f6fe7eb0c7c1",
  measurementId: "G-T57KQ07DPN"
};

const firebaseApp = initializeApp(firebaseConfig);
setFirebaseApp(firebaseApp);
const firestore = getFirestore(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const repositoryService = new RepositoryService(firestore);

router.isReady().then(() => {
  app.provide('firebaseApp', firebaseApp);
  app.provide('firestore', firestore);
  app.provide('repositoryService', repositoryService);
  app.mount('#app');
});